import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/spx-support",
  "date": "28th February 2024",
  "title": "Announcing SPX Support for Performance Profiling",
  "summary": "Announcing support for SPX! A simple, easy to use application performance profilng tool which is installed by default for local development environments.",
  "author": "Nick Schuch",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "Local Development"
  }, {
    "name": "Performance"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What is performance profiling`}</h2>
    <p>{`Performance profiling is the process of reviewing and debugging applications for bottlenecks and inefficiencies for areas of improvement.`}</p>
    <p>{`Excellent examples of application performance testing tools typically come in the form of a paid subscription e.g. `}<a parentName="p" {...{
        "href": "https://www.blackfire.io"
      }}>{`Backfire.io`}</a>{`.`}</p>
    <p>{`While on the Open Source side, popular tools such as `}<a parentName="p" {...{
        "href": "https://xdebug.org"
      }}>{`Xdebug`}</a>{` are missing features such as a web interface for analysing performance data.`}</p>
    <h2>{`SPX`}</h2>
    <p>{`SPX is one tool in this area which hits the sweet spot by not only being Open Source, but also providing a holistic out of the box performance profiling solution.`}</p>
    <p>{`SPX offers a great web interface for analysing performance data whether it comes from a web request or a command line execution.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/spx/web.gif",
        "alt": "Animated image showing SPX web console"
      }}></img></p>
    <h2>{`Installed by default`}</h2>
    <p>{`Today we are happy to announce that SPX is installed and configured by default in our local development images. Enabling development teams to immediately start
profiling their mission critical performance bottlenecks `}<strong parentName="p">{`with zero setup`}</strong>{`.`}</p>
    <h2>{`Your Next Steps`}</h2>
    <p>{`Please `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/integrations/spx"
      }}>{`read the documentation`}</a>{` for more information on how to get started today.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      